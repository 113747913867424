<template>
    <v-row class="mt-0">
        <v-col cols="12">
            <heading>お客さまの声</heading>
        </v-col>
        <v-col cols="4" :class="smAndUp ? 'pa-8' : null">
            <v-img :key="imageVoice1" :src="imageVoice1"></v-img>
        </v-col>
        <v-col cols="8" :class="smAndUp ? 'pa-8' : null">
            <div class="balloon-wrapper">
                <div class="balloon left">
                    <p>
                        顔が小さくなって小さな服も着れるようになりました！<br />
                        （20代: A.Yさん）
                    </p>
                </div>
            </div>
        </v-col>
        <v-col cols="8" :class="smAndUp ? 'pa-8' : null">
            <div class="balloon-wrapper">
                <div class="balloon right">
                    <p>
                        着てみたい服や出かけたい場所がたくさん増えました！<br />
                        （30代: M.Kさん）
                    </p>
                </div>
            </div>
        </v-col>
        <v-col cols="4" :class="smAndUp ? 'pa-8' : null">
            <v-img :key="imageVoice2" :src="imageVoice2"></v-img>
        </v-col>
        <v-col cols="4" :class="smAndUp ? 'pa-8' : null">
            <v-img :key="imageVoice3" :src="imageVoice3"></v-img>
        </v-col>
        <v-col cols="8" :class="smAndUp ? 'pa-8' : null">
            <div class="balloon-wrapper">
                <div class="balloon left">
                    <p>
                        40代の女性には絶対にオススメしたいです！<br />
                        （40代: A.Mさん）
                    </p>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import Heading from '@/components/Heading.vue'

export default {
    components: {
        Heading,
    },
    computed: {
        imageVoice1() {
            return '/static/pc_top/top_voice01@2x.png'
        },
        imageVoice2() {
            return '/static/pc_top/top_voice02@2x.png'
        },
        imageVoice3() {
            return '/static/pc_top/top_voice03@2x.png'
        },
        imageYobiko() {
            return '/static/pc_top/blog.png'
        },
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
}
</script>

<style lang="scss" scoped>
.balloon-wrapper {
    display: table;
    height: 100%;
}
.balloon {
    position: relative;
    margin: 1.5em 15px 1.5em 0;
    padding: 7px 10px;
    width: 100%;
    color: #555;
    font-size: 16px;
    background: #e0edff;
    border-radius: 10px;
    display: table-cell;
    vertical-align: middle;

    &.right {
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 100%;
            margin-top: -15px;
            border: 15px solid transparent;
            border-left: 15px solid #e0edff;
        }
    }

    &.left {
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: -30px;
            margin-top: -15px;
            border: 15px solid transparent;
            border-right: 15px solid #e0edff;
        }
    }
    p {
        margin: 0;
        padding: 0;
    }
}
</style>
