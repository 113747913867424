<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="6">
                <heading>パーソナルトレーニング</heading>
                <v-img
                    :key="imagePersonalTraining"
                    :src="imagePersonalTraining"
                ></v-img>
                <div class="text-body-1 ma-2">
                    お腹の脂肪、筋肉の衰え、悪い姿勢、肩こり、腰痛、体力の低下、カラダが固いなど。様々なカラダの悩みを解決する上で、REVOISTのパーソナルトレーニングでは、
                    あなたの運動レベルやその日の体調に合わせて、トレーナーが丁寧にマンツーマンでカウンセリング、筋トレ、コンディショニング、食事アドバイスなど無理なく楽しいレッスンを提供します。
                </div>
                <link-tag compName="PersonalTraining"
                    >パーソナルトレーニング</link-tag
                >
            </v-col>
            <v-col cols="12" md="6">
                <heading> 料金・会員種別 </heading>
                <v-img :key="imagePrices" :src="imagePrices"></v-img>
                <div class="text-body-1 ma-2">
                    パーソナルトレーニング業界への挑戦。
                    REVOISTはひとりでも多くのひとにとって、パーソナルトレーニングを受けやすく、長く続けやすいサービスにするための料金プランを設定。
                    あなたのペースにあった会員種別をお選びいただけます。<br />
                    週1回 50分 4980円（税抜）～
                </div>
                <link-tag compName="Price">料金・会員種別</link-tag>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import LinkTag from '@/components/LinkTag.vue'
import Heading from '@/components/Heading.vue'
export default {
    components: {
        LinkTag,
        Heading,
    },
    computed: {
        imagePersonalTraining() {
            return '/static/pc_top/top_prsonal.png'
        },
        imagePrices() {
            return '/static/pc_top/top_price.png'
        },
    },
}
</script>

<style lang="scss" scoped></style>
