m<template>
    <v-container>
        <v-row justify="center">
            <v-col
                cols="12"
                sm="9"
                xl="6"
                lg="9"
                md="9"
                :class="$vuetify.breakpoint.smAndUp ? 'ml-8' : null"
            >
                <v-card flat>
                    <v-card-text
                        ><span
                            :class="
                                $vuetify.breakpoint.smAndUp
                                    ? 'text-lg-h4 text-md-h5  text-sm-h6 font-weight-thin'
                                    : 'text-body-1 black--text'
                            "
                            class="leading"
                        >
                            「ダイエットやボディメイクをしたい！」<br />
                            「健康的に美しいカラダになりたい！」<br />
                            だけど、自分ひとりでは・・・<br />
                            正しいトレーニングや食事の方法がわからない<br />
                            ジムや食事制限を続けることができない<br />
                            できないのは、あなたのせいではありません。<br /><br />
                            レボイストはそんなあなたのために生まれました。<br /></span
                    ></v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.leading {
    line-height: 1.5em;
}
</style>
