<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6">
                <place></place>
                <link-tag compName="Gyms">店舗一覧・アクセス</link-tag>
            </v-col>
            <v-col cols="12" md="6">
                <v-row class="mt-0 mx-2">
                    <voice-of-customer></voice-of-customer>
                </v-row>
                <link-tag compName="Testimonial">お客さまの声</link-tag>
            </v-col>
            <v-col cols="12" md="6">
                <heading>よくあるご質問</heading>
                <div class="text-body-1 ma-2">
                    お客様からよく寄せられるご質問とその回答を掲載しています。
                    また、お客様のお声はサービスの改善にも活用しています。
                </div>
                <link-tag compName="Faq">よくあるご質問</link-tag>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Place from '@/components/top/Place.vue'
import VoiceOfCustomer from '@/components/top/VoiceOfCustomer.vue'
import LinkTag from '@/components/LinkTag.vue'
import Heading from '@/components/Heading.vue'
export default {
    components: {
        Place,
        VoiceOfCustomer,
        LinkTag,
        Heading,
    },
}
</script>

<style lang="scss" scoped></style>
