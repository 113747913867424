<template>
    <div>
        <jumbotron></jumbotron>

        <!-- Navigation -->
        <navigation></navigation>

        <!-- Information -->
        <!-- <information></information> -->

        <!-- Lead -->
        <leading></leading>

        <!-- What's revoist? -->
        <what-is-revoist></what-is-revoist>

        <!-- Menu1 -->
        <menu-1></menu-1>

        <!-- Trial lesson -->
        <trial-lesson></trial-lesson>

        <!-- Menu2 -->
        <menu-2></menu-2>

        <!-- Menu3 -->
        <menu-3></menu-3>

        <v-divider></v-divider>

        <!-- Contacts -->
        <contact></contact>

        <v-divider></v-divider>

        <!-- Logo -->
        <logo></logo>
    </div>
</template>

<script>
import Jumbotron from '@/components/top/Jumbotron.vue'
import TrialLesson from '@/components/TrialLesson.vue'
import Menu2 from '@/components/top/Menu2.vue'
import Navigation from '@/components/top/Navigation.vue'
// import Information from '@/components/top/Information.vue'
import Leading from '@/components/top/Leading.vue'
import WhatIsRevoist from '@/components/top/WhatIsRevoist.vue'
import Menu1 from '@/components/top/Menu1.vue'
import Menu3 from '@/components/top/Menu3.vue'
import Contact from '@/components/Contact.vue'
import Logo from '@/components/Logo.vue'

export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
    },
    components: {
        Jumbotron,
        TrialLesson,
        Menu2,
        Navigation,
        // Information,
        Leading,
        WhatIsRevoist,
        Menu1,
        Menu3,
        Contact,
        Logo,
    },
}
</script>

<style lang="scss" scoped></style>
