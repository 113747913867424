<template>
    <!-- Jambotron -->
    <v-container fluid color="white" class="pa-0 border-less">
        <v-img :key="image" :src="image" height="auto" elevation="0">
            <v-container white--text>
                <v-slide-x-transition appear>
                    <v-col justify="center">
                        <div class="inner-logo-wrapper">
                            <div class="inner-logo" v-if="smAndUp">
                                <router-link :to="{ name: 'Top' }">
                                    <v-img
                                        :class="
                                            $vuetify.breakpoint.mdAndUp
                                                ? 'large-logo'
                                                : 'small-logo'
                                        "
                                        :src="imageLogo"
                                    ></v-img>
                                </router-link>
                            </div>
                        </div>
                    </v-col>
                </v-slide-x-transition>
            </v-container>
        </v-img>
    </v-container>
</template>

<script>
export default {
    computed: {
        namespace() {
            if (!this.$route || !this.$route.name) return ''

            return this.$route.name.toLowerCase()
        },
        image() {
            return this.$vuetify.breakpoint.smAndUp
                ? '/static/pc_top/revoist-main-visual.png'
                : '/static/sp_top/revoist-main-visual.png'
        },
        imageLogo() {
            return '/static/sp_top/logo_revoist01.svg'
        },
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
}
</script>

<style lang="scss" scoped>
.inner-logo-wrapper {
    position: absolute;
    display: table;
    top: 7%;
    left: 12%;
    opacity: 0.9;
    z-index: 9;

    .inner-logo {
        display: table-cell;
        vertical-align: middle;

        .large-logo {
            width: 100%;
            height: 100%;
        }
        .small-logo {
            width: 50%;
            height: 50%;
        }
    }
}
.border-less {
    border: 0;
}
</style>
