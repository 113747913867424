<template>
    <v-container>
        <heading>レボイストとは</heading>
        <v-row class="mb-6">
            <v-col>
                <v-img
                    :key="imageTopRevoist"
                    :src="imageTopRevoist"
                    width="100%"
                    height="100%"
                >
                </v-img>
            </v-col>
        </v-row>
        <v-row justify="center"
            ><heading>レボイスト、3つのポリシー</heading></v-row
        >
        <v-row :justify="smAndUp ? 'center' : 'space-around'" class="mt-4">
            <v-col cols="3" :class="smAndUp ? 'pa-8' : 'pa-0'">
                <v-img
                    :key="imageOncePerAWeek"
                    :src="imageOncePerAWeek"
                    width="100%"
                    height="100%"
                >
                </v-img>
            </v-col>
            <v-col cols="3" :class="smAndUp ? 'pa-8' : 'pa-0'">
                <v-img
                    :key="imageBestPrice"
                    :src="imageBestPrice"
                    width="100%"
                    height="100%"
                >
                </v-img>
            </v-col>
            <v-col cols="3" :class="smAndUp ? 'pa-8' : 'pa-0'">
                <v-img
                    :key="imageCommunityBased"
                    :src="imageCommunityBased"
                    width="100%"
                >
                </v-img>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" md="10">
                <span class="text-body-1"
                    >REVOISTのパーソナルトレーニングでは、経験豊富なプロのトレーナーがひとりひとりのカラダに対する悩み、背景、想いにしっかりと寄り添い、あなたに合った効率的なトレーニングによって、楽しく、健康的にあなたのカラダを変えます。
                    時間、労力、料金など様々なコストに対するパフォーマンスにこだわりながら、ひとりひとりの価値観に沿って、期待に応え続ける。
                    だから、REVOISTは多くの方に選ばれ続けています。
                </span>
            </v-col>
            <v-col cols="12" md="8">
                <link-tag compName="Revoist">レボイストとは</link-tag>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Heading from '@/components/Heading.vue'
import LinkTag from '@/components/LinkTag.vue'
export default {
    components: {
        Heading,
        LinkTag,
    },
    computed: {
        imageTopRevoist() {
            const path = this.$vuetify.breakpoint.smAndUp
                ? '/static/pc_top/top_revoist.png'
                : 'static/sp_top/img_top01.png'
            return path
        },
        imageOncePerAWeek() {
            return '/static/pc_top/once_per_a_week.png'
        },
        imageBestPrice() {
            return '/static/pc_top/best_price.png'
        },
        imageCommunityBased() {
            return '/static/pc_top/community_based.png'
        },
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
    },
}
</script>

<style lang="scss" scoped></style>
